(function ($) {
    $(document).ready(function () {

        if ($('.errors-layout .alert').length) {
            $('.errors-layout').fadeOut(6000);
        }

        $('.dropdown-toggle').click(function() {
            $('.dropdown-menu').toggleClass('activate');
        });

    });


})(jQuery);